const privacyPolicyContent = `
  <h3 class="text-xl font-semibold mt-6 mb-2">Section 1: Introducere</h3>
    <p class="text-base mb-4">
    Betanova SRL, în calitate de operator de date cu caracter personal, prelucrează datele tale cu caracter personal în condiții de siguranță, cu respectarea legislației aplicabile privind prelucrarea datelor personale (Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016, privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date, Legea nr. 190/2018 privind măsuri de punere în aplicare a GDPR și/sau cu orice alte decizii pe care le poate emite Autoritatea Națională de Supraveghere a Protecţiei Datelor cu Caracter Personal (ANSPDCP) în calitate de autoritate de supraveghere privind protecția datelor cu caracter personal).

  </p>
    <p class="text-base mb-4">
Vă respectăm confidențialitatea și ne angajăm să vă protejăm datele personale!

  </p>

      <p class="text-base mb-4">
Prin intermediul site-urilor noastre, la începutul interacțiunii, vă transmitem această Notă de informare, în care vă vorbim despre modul în care prelucrăm și protejăm datele cu caracter personal și serviciile noastre oferite în concordanță cu Termenii și Condițiile de utilizare.

    </p>

      <h3 class="text-xl font-semibold mt-6 mb-2">
Date de identificare:
    </h3>
      <p class="text-base mb-4">SC Betanova SRL cu sediul social în Brașov, jud. Brașov, str. 13 Decembrie, nr. 119, bl. 88, fiind înregistrată la Registrul Comerțului sub nr. J08/1990/1992 și având Cod Unic de Înregistrare 1104751
</p>
   <h3 class="text-xl font-semibold mt-6 mb-2"> Date de contact: </h3>
  <p class="text-base mb-4"> Pentru informații suplimentare (întrebări, nelămuriri sau îngrijorări cu privire la modul în care tratăm orice problemă de confidențialitate) sau pentru a vă exercita drepturile, contactați prin email responsabilul nostru cu proțectia datelor la support@Dedica.ro.
</p>

  <h3 class="text-xl font-semibold mt-6 mb-2"> I. Ce prelucrare face obiectul prezentei Note de Informare?   <h3 class="text-xl font-semibold mt-6 mb-2">
  <h3 class="text-xl font-semibold mt-6 mb-2">Această Notă de informare se aplică prelucrării datelor cu caracter personal ale: </h3>
  <h3 class="text-xl font-semibold mt-6 mb-2">Vizitatorilor și utilizatorilor aplicației Dedica.ro. </h3>
  <p class="text-base mb-4">În eventualitatea în care nu vă încadrați în categoriile enumerate mai sus, vă rugăm să ne contactați la adresa support@Dedica.ro pentru a vă furniza o Notă de informare corespunzătoare situaţiei dumneavoastră.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">II. Ce informații colectăm despre dumneavoastră</h3>
  <p class="text-base mb-4">Colectăm și prelucrăm datele dumneavoastră cu caracter personal atunci când interacționați cu noi (de exemplu informațiile pe care ni le-ați furnizați voluntar prin logarea cu Google pe site-ul nostru) sau prin corespondența cu noi prin e-mail, telefon sau în orice altă modalitate.</p>
  <p class="text-base mb-4">Informațiile pe care ni le furnizați pot include numele dumneavoastră, adresa de email și, după caz, numele reprezentantului legal al companiei pe care o reprezentaţi, numele companiei, datele de identificare, adresa de e-mail și numărul de telefon.</p>
  <p class="text-base mb-4">Oferindu-ne aceste informaţii, noi le procesăm şi păstrăm în condiții de securitate și confidențialitate în baza noastră de date. Implicit le stocăm şi ulterior le ştergem conform precizărilor sipulate în prezenta Notă de informare privind prelucrarea datelor cu caracter personal. </p>
  <p class="text-base mb-4">Nu vom colecta sau prelucra date cu caracter personal sensibile legate de dumneavoastră. În cazul în care ne furnizați în mod voluntar date cu caracter personal (inclusiv date cu caracter personal sensibile) prin interacțiunea cu aplicația noastră și paginile de facebook sau prin corespondența cu noi prin telefon, e-mail sau în orice altă modalitate, din proprie voință și nu la cererea noastră, vom șterge astfel de date cu caracter personal din sistemele noastre dacă nu considerăm că prelucrarea acestora este necesară pentru un scop legitim al Betanova SRL, cu excepția cazului în care ați făcut aceste date publice, caz în care vom șterge aceste date de pe site-urile noastre numai dacă legea impune astfel sau dacă nu dorim să le păstrăm.</p>
  <p class="text-base mb-4">Când vizitați site-ul, este posibil să colectăm automat, cu ajutorul Google Analytics informații despre următoarele date: </p>
  <p class="text-base mb-4">a) date tehnice, de exemplu acest lucru poate include Adresa Internet Protocol (IP) utilizată pentru conectarea calculatorului dumneavoastră la internet, informațiile de conectare, tipul și versiunea de browser, setarea fusului orar, tipurile și versiunile extensiilor de browser (plug-in), sistemul și platforma de operare, tipul dispozitivului și marca dispozitivului mobil; aceste date pot fi colectate și prelucrate în numele nostru prin fişierele de tip cookie ale terțelor părți; </p>
  <p class="text-base mb-4">b) date despre vizita dumneavoastră, de exemplu acest lucru poate include: secvențe de clicuri către, prin și de la site-urile noastre (inclusiv data și ora), informațiile sau produsele pe care le-ați vizualizat sau căutat (inclusiv timpii de răspuns al paginilor, erorile de descărcare, durata vizitelor pe anumite pagini (inclusiv timpul mediu petrecut pe anumite pagini), informații privind interacțiunea cu paginile sau orice număr de telefon folosit pentru a apela sau orice adresă de e-mail folosită pentru a contacta reprezentanții echipei de vânzări a departamentului nostru de publicitate; </p>
  <p class="text-base mb-4">Suplimentar, aplicația utilizează servicii YouTube, iar utilizatorii sunt obligați să ia la cunoștiință politica de confidențialitate a Google (http://www.google.com/policies/privacy) și termenii de utilizare ai YouTube (https://www.youtube.com/t/terms). </p>
  <h3 class="text-xl font-semibold mt-6 mb-2">III. În ce scop prelucrăm date cu caracter personal și care este temeiul juridic al prelucrării </h3>
   <p class="text-base mb-4">Putem prelucra datele dumneavoastră cu caracter personal în cazul în care avem ca scop: </p>

să vă oferim anumite informații despre serviciile noastre ( răspundem la întrebări/ solicitări);
un interes legitim din motive tehnice, operative și funcționale, spre exemplu: securizare împotriva atacurilor cibernetice; pentru a îmbunătăți siteul, funcționalitatea și administrarea lui; pentru operațiunile interne, inclusiv în scopuri de depanare, analiză a datelor, testare, statistică; pentru publicitate și marketing, inclusiv pentru scopuri de marketing specific, astfel încât să oferim servicii publicitare adecvate, inclusiv oferte publicitare personalizate, care ar putea fi de interes mai mare pentru dumneavoastră);
apărarea intereselor legitime ale unei terțe părți;
pentru a proteja interesele dumneavoastră (de exemplu, pentru asigurarea securității IT și a rețelelor), cu excepția cazului în care drepturile dumneavoastră fundamentale prevalează față de aceste interese; și/sau în cazul în care este necesar pentru interesul public sau în scopuri oficiale;
  <p class="text-base mb-4">Temei juridic:  <p class="text-base mb-4">
  <p class="text-base mb-4">a) În cazul în care ne oferiți voluntar datele dumneavoastră personale prin completarea și trimiterea formularelor sau prin contactarea noastră în orice mod, temeiul juridic utilizat pentru prelucrarea datelor cu caracter personal este pentru "luarea unor măsuri la cererea persoanei vizate înaintea încheierii unui contract", conform art. 5 alin. 2 lit. (a) din Legea nr. 677/2001 și/sau "pentru a face demersuri la cererea persoanei vizate înainte de încheierea unui contract", conform art. 6 alin. (1) lit. b din Regulamentul (UE) 679/2016). În conformitate cu legislația actuală, consimțământul dumneavoastră nu trebuie solicitat în situația în care prelucrarea este necesară pentru efectuarea de demersuri: în vederea încheierii unui contract/ satisfacerii unei cereri de ofertă, în vederea îndeplinirii unei obligații legale sau când operatorul utilizează aceste date în interes legitim. b) În alte cazuri solicităm date cu caracter personal pentru a respecta obligații legale sau contractuale, furnizarea acestor date cu caracter personal de către dumneavoastră este obligatorie deoarece dacă aceste date nu sunt furnizate, nu vom putea să gestionăm relațiile contractuale sau să respectăm obligațiile care ne sunt impuse. În toate celelalte cazuri, furnizarea de date cu caracter personal este opțională și nu aveți obligația de a le furniza.</p>
  <p class="text-base mb-4">c) În unele cazuri, vom prelucra datele dumneavoastră cu caracter personal doar cu consimțământul dumneavoastră, solicitat separat și în mod transparent, atunci când furnizați datele dumneavoastră cu caracter personal. De exemplu, pentru datele pe care le colectăm automat prin folosirea cookie-urilor sau a altor tehnologii similare, temeiul pentru prelucrare este consimțământul. Consimțământul este temeiul legal aplicabil și în cazul folosirii datelor prelucrate în activități de marketing si publicitate. Odată cu accesarea site-ului, cu parcurgerea secțiunii de Termeni și Condiții și implicit cu accesarea serviciilor oferite de către site-urile noastre și cele ale Companiilor noastre Afiliate, dumneavoastră acordați în mod valabil și explicit consimțământul asupra prelucrării datelor cu caracter personal (de exemplu, fie printr-un câmp de bifat într-un formular, fie prin contactarea dumneavoastră prin intermediul poştei electronice).</p>
  <p class="text-base mb-4">Ulterior, în orice moment, veți putea să vă retrageți sau să restrângeți consimțământul prin transmiterea unui e-mail la support@Dedica.ro cu subiect RETRAGERE CONSIMȚĂM NT. Cu toate acestea, retragerea consimţământului nu va afecta legalitatea niciunei prelucrări care a avut loc înainte de retragerea acestuia.</p>
  <p class="text-base mb-4">d) Putem prelucra datele dumneavoastră cu caracter personal, precum datele de identificare, datele de contact și adresa de reședință, în scopul posibilei exercitări a drepturilor sau a reclamațiilor noastre împotriva dumneavoastră în viitor. Această prelucrare se bazează pe interesul nostru legitim, fiind necesar să ne exercităm drepturile în cazul unor eventuale litigii.</p>
  <p class="text-base mb-4">În cele mai multe situații, prelucrarea datelor partenerilor comerciali are ca scop furnizarea de servicii:</p>
  <p class="text-base mb-4">De informare ( răspundem la întrebări/ solicitări); de preluare și publicare a anunțurilor în presă, de livrare a materialelor publicitare în care au apărut anunțurile atât fizic cât și prin intermediul poștei electronice; de efectuare plăți</p>
  <p class="text-base mb-4">Fiind partenerul nostru comercial, vă putem furniza comunicări despre noile noastre produse și servicii. Dacă nu mai doriți să primiți aceste comunicări, puteți renunța trimițându-ne un e-mail la adresa support@Dedica.ro.</p>
  <p class="text-base mb-4">Dacă sunteți un client existent, vă vom contacta preponderent prin intermediul e-mail-ului cu informații despre servicii similare cu cele care au făcut obiectul unei vânzări anterioare sau unei negocieri cu dumneavoastră în vederea vânzării, cu excepția cazului în care ați optat altfel anterior. Dacă sunteți un client nou, vă vom contacta prin mijloace electronice numai dacă avem acordul dumneavoastră prealabil. Dacă nu doriți să vă folosim datele în acest fel sau să restrângeţi consimțământul vă rugăm să ne transmiți un e-mail la support@Dedica.ro. cu subiect CONSIMȚĂM NT.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">IV. Cât timp stocăm datele dumneavoastră cu caracter personal </h3>
  <p class="text-base mb-4">Datele dumneavoastră cu caracter personal pe care le colectăm sunt stocate în România. Betanova SRL va păstra datele dumneavoastră cu caracter personal în baza de date pe perioada necesară îndeplinirii scopurilor și implicit pentru a le utiliza în cazul în care vor exista oportunități de colaborare viitoare care s-ar potrivi descrierii serviciului solicitat de către dumneavoastră. Această prelucrare este bazată pe interesul legitim al Betanova SRL în legătură cu serviciile oferite de preluare și publicare anunțuri publicitare.</p>
  <p class="text-base mb-4">Vom stoca datele dumneavoastră cu caracter personal doar atât timp cât va fi necesar, dar nu mai mult de 5 ani de la ultima vizita pe site sau ultima interactiune cu noi sau pentru o perioadă care nu depășește durata impusă de legislația aplicabilă, în conformitate cu perioadele minime legale de păstrare aplicabile și/sau atât cât este necesar pentru a ne exercita drepturile legitime (și drepturile legitime ale altor persoane).</p>
  <p class="text-base mb-4">În cazul în care prelucrăm datele dumneavoastră cu caracter personal în temeiul consimțământului dumneavoastră, aceste date cu caracter personal vor fi prelucrate numai pentru perioada prevăzută prin consimțământul dumneavoastră, cu excepția cazului în care vă retrageți sau limitați consimțământul înainte de expirarea acestei perioade. În astfel de cazuri, vom înceta prelucrarea datelor cu caracter personal respective în scopurile relevate, sub rezerva oricărei obligații legale de a prelucra aceste date cu caracter personal și/sau a nevoii noastre de a prelucra aceste date cu caracter personal în scopul exercitării drepturilor noastre legitime (inclusiv a drepturilor legitime ale altor persoane).</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">V. Cum și cui dezvăluim datele dumneavoastră cu caracter personal </h3>
  <p class="text-base mb-4">Nu vindem datele dumneavoastră cu caracter personal unor terțe părți.  <p class="text-base mb-4">
  <p class="text-base mb-4">a) în cadrul Betanova SRL, doar un număr limitat de membri ai personalului, cum ar fi cei din departamentele de Vânzări, Juridic și IT au acces la datele dumneavoastră cu caracter personal conform principiului nevoii de a cunoaște (”on a need to know basis”). Acești membrii ai personalului sunt supuși unor obligații de confidențialitate cu privire la datele cu caracter personal. Măsuri tehnice și organizatorice adecvate sunt luate pentru protecția datelor cu caracter personal. Membrii personalului Betanova au dreptul să gestioneze datele cu caracter personal numai la instrucțiunile societății și, dacă este necesar, în legătură cu responsabilitățile de serviciu;  <p class="text-base mb-4">
  <p class="text-base mb-4">b) instanțe de judecată, parchete sau alte autorități publice pentru a ne conforma legii sau pentru protejarea intereselor noastre legitime (inclusiv interesele legitime ale unor terțe părți) în conformitate cu legile aplicabile;  <p class="text-base mb-4">
  <p class="text-base mb-4">c) unor terțe părți, care vor avea o capacitatea (restrânsă) de a utiliza informațiile referitoare la dumneavoastră în scopul de a ne furniza servicii și care acționează în calitate de persoane împuternicite, în cele mai multe cazuri. Aceste informații nu vor putea fi folosite în propriile scopuri de marketing.</p>
  <p class="text-base mb-4">Spre exemplu, unor: furnizori de servicii care oferă sprijin administrativ, profesional și tehnic societății pentru suport IT, de securitate și resurse comerciale; parteneri de afaceri, furnizori și subcontractanți pentru executarea tuturor contractelor pe care le încheiem cu dumneavoastră; furnizorii de servicii de analiză și de motoare de căutare care ne ajută în îmbunătățirea și optimizarea site-urilor noastre; consultanți externi (de exemplu, avocați, contabili, auditori), dacă este necesar; și/sau unor terțe părți cu consimțământul sau la instrucțiunile dumneavoastră;</p>
  <p class="text-base mb-4">Betanova își propune să efectueze o evaluare prealabilă adecvată în selectarea terțelor părți furnizori de servicii și impune acestor furnizori să mențină măsuri de securitate tehnice și organizatorice adecvate pentru a proteja datele cu caracter personal și pentru a prelucra datele cu caracter personal numai conform instrucțiunilor Betanova. Furnizorii de servicii vor avea dreptul să utilizeze subcontractanți în furnizarea serviciilor către Betanova, cu condiția ca subcontractanții să respecte aceleași obligații de protecție a datelor ca și furnizorii de servicii.</p>
  <p class="text-base mb-4">d) În unele cazuri, putem dezvălui date cu caracter personal grupului de societăți din care face parte Betanova pe baza principiului nevoii de a cunoaște (”on a need to know basis”). Am putea partaja date cu caracter personal cu societățile din grup și cu alte terțe părți în contextul anumitor tipuri de tranzacții.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">VI. Transferul de date</h3>
  <p class="text-base mb-4">În prezent, nu transferăm date cu caracter personal către state terțe din afara Uniunii Europene și/sau organizații internaționale! În situația în care va fi acest lucru necesar vă vom solicitata anterior consimțământul prezentându-mă implicit și garanțiile aferente unui astfel de transfer.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">VII. Drepturile dumneavoastră </h3>
  <p class="text-base mb-4">Conform prevederilor cap. III, art. 12-22 din Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date, persoanele vizate beneficiază de următoarele drepturi: </p>
  <p class="text-base mb-4">a) Dreptul de a fi informat:  </p>
  <p class="text-base mb-4">Prin prezenta politica de confidenţialitate, beneficiarii serviciilor Betanova, implicit ai site-ului Dedica.ro sunt informați cu privire la identitatea și datele noastre de contact, datele de contact ale responsabilului cu protecția datelor, scopurile prelucrării datelor personale și temeiul juridic al prelucrării, categoriile de destinatari ai datelor cu caracter personal, transferul către țări terțe sau organizații internaționale, perioada de stocare a datelor, implicit și repturile pe care dumneavoastră le aveți. </p>
  <p class="text-base mb-4">b) Dreptul de a avea acces la datele cu caracter personal prelucrate:</p>
  <p class="text-base mb-4">Puteți să ne solicitați să vă confirmăm dacă datele dumneavoastră cu caracter personal sunt sau nu prelucrate de noi, să vă furnizăm o copie a datelor dumneavoastră cu caracter personal care fac obiectul prelucrării. Pentru orice alte copii solicitate, putem percepe o taxă rezonabilă, bazată pe costurile administrative. Informaţiile vor fi furnizate într-un format electronic, utilizat în mod curent. Ne rezervăm dreptul de a nu răspunde solicitărilor vădit neîntemeiate, excesive sau repetate. Dacă intenționați să uzați de acest drept vă rugăm să ne transmiți un e-mail la support@Dedica.ro cu subiect: ACCESARE DATE. </p>
  <p class="text-base mb-4">c) Dreptul de a vă retragere consimțământul: </p>
  <p class="text-base mb-4">În cazul în care v-am solicitat consimțământul pentru prelucrarea datelor cu caracter personal, aveți dreptul să retrageți acest consimțământ fără efecte negative asupra dumneavoastră și în mod gratuit prin transmiterea unui e-mail la support@Dedica.ro cu subiect: RESTRAGERE CONSIMȚĂM NT</p>
  <p class="text-base mb-4">e)Dreptul la ștergerea datelor cu caracter personal prelucrate ("dreptul de a fi uitat", fără întârzieri nejustificate):</p>
  <p class="text-base mb-4">În anumite circumstanțe, când se aplică unul din următoarele motive, aveți dreptul să ne solicitați ștergerea datele dumneavoastră cu caracter personal: datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate; vă retrageți consimțământul pe baza căruia are loc prelucrarea și nu există nici un alt temei juridic pentru prelucrare; vă opuneți prelucrării; datele cu caracter personal au fost prelucrate ilegal; datele cu caracter personal trebuie şterse pentru respectarea unei obligații legale care revine Betanova; datele cu caracter personal au fost colectate în legătură cu oferirea de servicii ale societății informaționale în mod direct unui copil;</p>
  <p class="text-base mb-4">Conform dispoziţiilor legale, obligația de a șterge datele dumneavoastră nu se aplică în masura în care prelucrarea este necesară: pentru respectarea unei obligații legale sau pentru îndeplinirea unei sarcini executate în interes public, în scopuri de arhivare în interes public ori în scopuri statistice sau pentru constatarea, exercitarea sau apărarea unui drept în instanță.</p>
  <p class="text-base mb-4">Dacă intenționați să uzați de acest drept vă rugăm să ne transmiți un e-mail la support@betanova.ro cu subiect: ȘTERGERE DATE. Din momentul acela informațiile vor deveni inactive pentru o perioadă de maxim 30 de zile, în vederea investigării solicitării și ștergerii definitive a acestora, acolo unde este cazul. </p>
  <p class="text-base mb-4">f) Dreptul la restricționarea prelucrării datelor cu caracter personal Puteți să solicitați restricționarea prelucrării în cazul în care: contestați exactitatea datelor, pentru o perioadă în care să avem posibilitatea verificării acestor informații; prelucrarea este ilegală, pe perioada în care rezolvăm solicitarea; Betanova SRL nu mai avem nevoie de datele cu caracter personal în scopul prelucrării acestora, dar dumneavoastră le solicitați pentru constatarea, exercitarea sau apărarea unui drept în instanță; administratorul site-urilor se opune restricționării, pentru intervalul de timp în care se verifică dacă drepturile legitime ale acestuia din urmă prevalează asupra celor ale dumneavoastră. Dacă intenționați să uzați de acest drept vă rugăm să ne transmiți un e-mail la la support@betanova.ro subiect: RESTRICȚIONARE DATE.</p>
  <p class="text-base mb-4">g) Dreptul de a depune o plângere în fața unei autorități de supraveghere</p>
  <p class="text-base mb-4">h) Dreptul de opoziție la prelucrarea datelor cu caracter personal:</p>
  <p class="text-base mb-4">Puteți să vă opuneți, în orice moment, din motive întemeiate și legitime legate de situația dumneavoastră particulară, ca date care vă vizează să nu facă obiectul unei prelucrări, cu excepția cazurilor în care există dispoziții legale contrare. În caz de opoziție justificată, prelucrarea nu mai poate viza datele în cauză.</p>
  <p class="text-base mb-4">l) Dreptul de a nu fi supus unui proces decizional individual automatizat Puteți să ne solicitați și să obțineți retragerea/ anularea/ reevaluarea oricărei decizii care produce efecte juridice în privința dumneavoastră (produc efecte juridice care vă privesc sau vă afectează în mod similar într-o măsură semnificativă), decizie adoptată exclusiv pe baza unei prelucrări de date cu caracter personal, efectuată prin mijloace automate și fiind întreprinsă cu scopul de a evalua unele aspecte ale personalității dumneavostră, precum comportamentul sau ori alte asemenea aspecte.</p>
  <p class="text-base mb-4">Nu veți putea uza de acest drept în cazul în care decizia: este necesară pentru încheierea sau executarea unui contract între dumneavostră și un operator de date; acestă prelucrare este autorizată prin dreptul Uniunii sau dreptul intern care se aplică operatorului și care prevede, de asemenea, măsuri corespunzătoare pentru protejarea drepturilor, libertăților și intereselor legitime ale persoanei vizate; sau are la bază consimțământul dumneavoastră explicit.</p>
  <p class="text-base mb-4">n) Dreptul de a vă adresa justiției Fără a se aduce atingere posibilității de a vă adresa cu plângere autorității de supraveghere privind protecția datelor personale, dumneavostră puteți uza de dreptul de a vă adresa justiției pentru apărarea oricăror drepturi garantate de lege, care considerați că v-au fost încălcate. În situația în care considerați că ați suferit un prejudiciu în urma unei prelucrării de date cu caracter personal, efectuată ilegal, aveți posibilitatea de a vă adresa instanței competente pentru repararea acestuia.</p>
 <h3 class="text-xl font-semibold mt-6 mb-2">VIII. Restricții privind vârsta: </h3>
  <p class="text-base mb-4">Pentru a folosi serviciile site-urilor noastre trebuie să aveți minim 18 ani. Prin completarea datelor și prin acceptarea Termenilor și Condițiilor, Utilizatorul certifică Administratorului Site-ului că are cel puțin 18 ani și că potrivit prevederilor legale în materie are dreptul să utilizeze Serviciile și își asumă responsabilitatea completă pentru alegerea și utilizarea Serviciului, implicit pentru informația publicată. Dacă aveți vârsta mai mică de 16 ani, te rugăm să obţii în prealabil de la părinţii sau tutorele tău permisiunea de a ne oferi aceste date cu caracter personal.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">IX. Securitatea datelor</h3>
  <p class="text-base mb-4">Vă informăm că păstrăm datele dumneavoastră pe serverele noastre și pe servere găzduite de terțe părți (inclusiv serviciilor de cloud ale terţelor părţi cloud - ”cloud-based services”). Folosim măsuri tehnice și organizatorice adecvate, menite să vă protejeze datele cu caracter personal și să împiedice accesul neautorizat. Am intrat în relații contractuale cu terţe părţi care oferă servicii de găzduire și aceste contracte includ obligații privind securitatea organizațională și tehnică a datelor cu caracter personal.</p>
  <p class="text-base mb-4">Betanova SRL evaluează în permanență securitatea rețelei și gradul de adecvare al programului nostru intern de securitate a informațiilor, inclusiv prin evaluarea riscurilor și prin testarea periodică pentru a vă ajuta: să vă asigurați datele împotriva pierderii, accesului sau divulgării accidentale sau ilegale; să identificați riscurile previzibile în mod rezonabil pentru securitatea noastră; să minimizați riscurile de securitate. Toate tranzacțiile de plată vor fi criptate (de exemplu, utilizând protocolul TLS sau SSL). </p>
  <p class="text-base mb-4">Rețineți că: sunteți responsabil pentru păstrarea confidențialității tuturor mijloacelor de autentificare (de exemplu, parole utilizate în aplicațiile dezvoltate de noi) utilizate de dumneavoastră pentru a accesa părți ale aplicatiei.</p>
  <p class="text-base mb-4">Transmiterea datelor prin internet, chiar și în situația implementării acestor măsuri de securitate pentru a vă proteja datele cu caracter personal, nu este complet sigură existând riscul ca datele dumneavoastră să fie accesate de terți neautorizați. După ce primim datele dumneavoastră, vom folosi proceduri stricte și caracteristici de securitate pentru a încerca să împiedicăm accesul neautorizat.</p>
  <h3 class="text-xl font-semibold mt-6 mb-2">X. Actualizări </h3>
  <p class="text-base mb-4">Prezenta Politică privind protecţia datelor cu caracter personal este aplicabilă începând cu 04 octombrie 2024. </p>
  <p class="text-base mb-4">Vom actualiza prezenta Notă de informare privind Politica de Confidențialitate ori de câte ori vom considera că este necesar și vă vom notifica aceste modificări: prin postarea de noi variante pe siteul noastru web, proprietatea Betanova SRL sau prin transmiterea unei informări prin e-mail în cazul în care ne-ați furnizat datele dumneavoastră de contact și ne-ați autorizat să vă trimitem aceste informări.</p>

`

export default privacyPolicyContent;
